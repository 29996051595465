import React, { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import scrollTo from 'gatsby-plugin-smoothscroll'
import BlogPlate from '../../../reusable/BlogPlate/BlogPlate'
import * as style from './blog-plates.module.scss'
import BlogFilters from '../BlogFilters/BlogFilters'
import BlogTagsFilter from '../BlogTags/BlogTagsFilter'
import BlogPagination from '../BlogPagination/BlogPagination'
import usePostPerPage from '../../../../hooks/usePostPerPage'
import {
  ALL_TAGS_NAME,
  filterPosts,
  generateTagsBySelectedCategory,
  initTagsAndCategories,
  sortActiveTag,
  setPaginationChunk,
} from './functions'

const scrollPointId = 'category-filter-title'

const initElem = {
  name: ALL_TAGS_NAME,
  slug: 'all',
}

const BlogPlates = ({ posts }) => {
  const { itemsPerPage } = usePostPerPage()
  const { initTags, initCategories } = initTagsAndCategories(posts)

  const [blogPosts, setBlogPosts] = useState(posts)
  const [blogPostChunks, setBlogPostChunks] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const [tags, setTags] = useState(initTags)
  const [activeCategory, setActiveCategory] = useState(initElem)
  const [activeTag, setActiveTag] = useState(initElem)

  const [tagQuery] = useQueryParam('tag', StringParam)
  const [categoryQuery] = useQueryParam('category', StringParam)

  const filterPostByCategoryAndTag = () => {
    const filteredPosts = filterPosts(
      posts,
      activeCategory.name,
      activeTag.name
    )

    if (filteredPosts.length) {
      setBlogPosts(filteredPosts)
    } else {
      setActiveTag(initElem)
    }
    setCurrentPage(0)
  }

  const sortActiveTagAndSetItToState = (oldTags) =>
    sortActiveTag(oldTags, activeTag.name)

  const setNewPostsChunks = (oldPosts) => {
    const newPostsChunks = setPaginationChunk(
      oldPosts,
      itemsPerPage,
      currentPage
    )
    setBlogPostChunks(newPostsChunks)
  }

  useEffect(() => {
    setNewPostsChunks(blogPosts)
  }, [blogPosts])

  useEffect(() => {
    const newTags =
      activeCategory.name === ALL_TAGS_NAME
        ? initTags
        : generateTagsBySelectedCategory(activeCategory, posts)

    const sortedTags = sortActiveTagAndSetItToState(newTags)
    setTags(sortedTags)

    filterPostByCategoryAndTag()
  }, [activeCategory])

  useEffect(() => {
    const sortedTags = sortActiveTagAndSetItToState(tags)
    setTags(sortedTags)
    filterPostByCategoryAndTag()
  }, [activeTag])

  useEffect(() => {
    if (categoryQuery) {
      const category =
        initCategories.find((c) => c.slug === categoryQuery) || initElem
      setActiveCategory(category)
    }
    if (tagQuery) {
      const tag = tags.find((t) => t.slug === tagQuery) || initElem
      setActiveTag(tag)
    }

    if (categoryQuery || tagQuery) {
      setTimeout(() => {
        scrollTo(`#${scrollPointId}`)
      }, 1000)
    }
  }, [tagQuery, categoryQuery])

  return (
    <div>
      <div className={style.wrap}>
        <div>
          <BlogFilters
            scrollToId={scrollPointId}
            categories={initCategories}
            setActive={setActiveCategory}
            activeCategory={activeCategory}
          />
          <BlogTagsFilter
            scrollToId={scrollPointId}
            tags={tags}
            activeTag={activeTag}
            setActiveTag={setActiveTag}
          />
        </div>
        <div>
          {blogPostChunks.length ? (
            <div className={style.plates}>
              {blogPostChunks[currentPage]?.map((post) => (
                <div key={post.title} className={style.post}>
                  <BlogPlate data={post} tags={post.categories.nodes} />
                </div>
              ))}
            </div>
          ) : (
            <div>No posts found</div>
          )}
        </div>
      </div>
      {blogPostChunks.length > 1 && (
        <div className={style.pagination}>
          <BlogPagination
            scrollToId={scrollPointId}
            currentPage={currentPage}
            pages={blogPostChunks.length}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  )
}
export default BlogPlates
