import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import * as style from './last-post-component.module.scss'
import * as authorStyle from '../../../reusable/BlogPlate/blog-plate.module.scss'
import PostAuthors from '../../OnePost/PostMainSection/PostAuthors'
import Section from '../../../common/SectionComponent/Section'
import BlogTags from '../../../common/BlogTags/BlogTags'

const LastPostComponent = ({
  post: {
    blogPost: { image, title, blogPrewiev, author, minutesToRead },
    uri,
    categories,
  },
}) => {
  const img = getImage(image.localFile)

  const profile = author[0]
  const { blogAuthor } = profile
  return (
    <Link to={uri}>
      <div className={style.wrap}>
        <div className={style.image}>
          <GatsbyImage objectFit="cover" alt={image.altText} image={img} />
        </div>

        <div className={style.content}>
          <BlogTags tags={categories.nodes} />
          <div className={style.text}>
            <h5>{title}</h5>
            <p className={style.description}>{blogPrewiev}</p>
            <div className={authorStyle.user}>
              <img
                className={authorStyle.avatar}
                src={blogAuthor.photo.localFile.url}
                alt={blogAuthor.photo.altText}
                width={56}
                height={56}
              />

              <div>
                <p className={authorStyle.authorName}>{profile.name}</p>
                <p className={authorStyle.timeToRead}>
                  {minutesToRead} min read
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default LastPostComponent
