import React, { useState } from 'react'
import cn from 'classnames'
import * as style from './post-main-section.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
import * as sliderStyle from './authors-slider.module.scss'

const PostAuthors = ({ authors }) => {
  const [avatars, setAvatars] = useState(authors)

  function rotate(arr, n) {
    const len = arr.length
    return !(n % len)
      ? arr.slice()
      : arr.map((e, i, a) => a[(i + (len + (n % len))) % len])
  }

  const triggerAvatar = (index) => {
    const newArr = rotate(avatars, index)
    setAvatars(newArr)
  }

  const Author = ({ author, isActive }) => (
    <div className={cn(style.authorWrap, isActive && style.activeCite)}>
      <p className={style.authorName}>{author.name}</p>
      <blockquote className={style.authorBlockquote}>
        <p>{author.description}</p>
      </blockquote>
    </div>
  )
  const AuthorsSlider = () => {
    const [activeItem, setActiveItem] = useState(1)
    const [clients, setClients] = useState(authors)

    const activeItemToggle = (index) => {
      const arr = Array.from(clients)
      const active = arr.splice(index, 1)[0]
      arr.unshift(active)
      setClients(arr)
    }
    const nextSlide = () => {
      const activeSlide = activeItem === clients.length - 1 ? 0 : activeItem + 1
      setActiveItem(activeSlide)
    }
    const prevSlide = () => {
      const activeSlide = activeItem === 0 ? clients.length - 1 : activeItem - 1
      setActiveItem(activeSlide)
    }

    return (
      <div>
        <div className={sliderStyle.blockNav}>
          <button
            aria-label="prevision"
            type="button"
            className={cn(sliderStyle.blockButton, sliderStyle.blockButtonPrev)}
            onClick={prevSlide}
            onKeyDown={prevSlide}
          />
          {clients.map((item, index) => (
            <div
              tabIndex={0}
              role="button"
              key={keyGenerator(index, item.name)}
              onKeyDown={() => activeItemToggle(index)}
              onClick={() => activeItemToggle(index)}
              className={cn(
                sliderStyle.blockImages,
                activeItem === index && sliderStyle.blockImagesActive
              )}
            >
              <img
                title={item.name}
                loading="lazy"
                className={sliderStyle.blockPortrait}
                src={
                  item.blogAuthor.photo.localFile.childImageSharp
                    .gatsbyImageData.images.fallback.src
                }
                width="100"
                height="100"
                alt={item.name}
              />
            </div>
          ))}
          <button
            aria-label="next"
            type="button"
            className={cn(sliderStyle.blockButton, sliderStyle.blockButtonNext)}
            onClick={nextSlide}
          />
        </div>
        <div>
          {avatars.map((author, index) => (
            <Author
              key={author.name}
              author={author}
              isActive={index === activeItem}
            />
          ))}
        </div>
      </div>
    )
  }
  return (
    <div className={style.author}>
      <div className="container">
        <div className={style.authorDesktop}>
          <div className={cn(style.avatars)}>
            {avatars.map((author, index) => {
              const avatar =
                author.blogAuthor.photo.localFile.childImageSharp
                  .gatsbyImageData.images.fallback.src
              return (
                <div
                  key={keyGenerator(index, author.name)}
                  aria-hidden
                  onClick={() => triggerAvatar(index)}
                  className={cn(
                    style.avatar,
                    index === 0 && style.activeAvatar
                  )}
                >
                  <img
                    src={avatar}
                    alt={author.name}
                    width="100"
                    height="100"
                  />
                </div>
              )
            })}
          </div>
          <div>
            {avatars.map((author, index) => (
              <Author
                key={keyGenerator(index, author.name)}
                author={author}
                isActive={index === 0}
              />
            ))}
          </div>
        </div>
        <div className={style.authorMobile}>
          {avatars.length > 1 ? (
            <AuthorsSlider />
          ) : (
            <div>
              <div aria-hidden className={cn(style.avatar, style.singleAvatar)}>
                <img
                  width="100"
                  height="100"
                  src={
                    avatars[0].blogAuthor.photo.localFile.childImageSharp
                      .gatsbyImageData.images.fallback.src
                  }
                  alt={avatars[0].name}
                />
              </div>
              <Author author={avatars[0]} isActive />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PostAuthors
