import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { createPortal } from 'react-dom'
import * as style from './subscribe.module.scss'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'
import { reg } from '../../../reusable/Form/Form'
import * as modalStyles from '../../../common/Modal/ModalFormSuccess/modal-success.module.scss'
import { AppContext } from '../../../../utils/context'
import HiddenScrollItem from '../../../common/HiddenScrollItem/HiddenScrollItem'

const portalRoot = typeof document !== `undefined` ? document.body : null

export const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

const Subscribe = (props) => {
  const [successState, setSuccessState] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [, setModalState] = useContext(AppContext)

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm()
  const formName = 'subscribe'

  const onSubmit = async (data) => {
    setSuccessState(true)
    setModalState(true)
    setDisableSubmit(true)

    const response = await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        ...data,
      }),
    })
    reset()
    setDisableSubmit(false)
  }

  const close = () => {
    setSuccessState(false)
    setModalState(false)
    setDisableSubmit(false)
  }

  const SubscribeSuccess = () =>
    createPortal(
      <div className={style.popup} {...props}>
        <div className={style.popupContent}>
          <button type="button" onClick={close} className={style.close}>
            ✕
          </button>
          <div className={modalStyles.thanks}>
            <div className={modalStyles.container}>
              <p className={modalStyles.title}>You’re in!</p>
              <p className={modalStyles.subtitle}>
                Thank you for signing up for the Visartech blog.
              </p>
              <p className={cn(modalStyles.subtitle, modalStyles.subtitleMd)}>
                Get ready to level up your technology game with our expert
                content.
              </p>
              <div className={modalStyles.additional}>
                <p className={modalStyles.description}>
                  Want more tech insights? Make sure to follow us on
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/visartech"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                  and
                  <a
                    target="_blank"
                    href="https://twitter.com/VISARTECH"
                    rel="noreferrer"
                  >
                    Twitter
                  </a>
                </p>
              </div>
              <ButtonPrimary onClick={close} btnClass={modalStyles.button}>
                Back To Blog
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>,
      portalRoot
    )

  return (
    <section className={style.wrap}>
      <HiddenScrollItem id="subscribe-section" />
      {successState && <SubscribeSuccess />}
      <div className="container">
        <h2 className={style.title}>Tech Insights by Visartech</h2>
        <p className={style.description}>
          Thoughts on more fulfilling ways to do business.
        </p>
        <form
          method="POST"
          autoComplete="off"
          className={cn('sample-form main-form__body', style.form)}
          name={formName}
          data-netlify="true"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={cn(style.input)}>
            <label className={style.label} htmlFor="subscribe-email">
              <input
                autoComplete="off"
                id="subscribe-email"
                className={style.input}
                type="text"
                name="email"
                placeholder="Email *"
                {...register('email', {
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                  pattern: {
                    value: reg,
                    message: 'Please, enter a valid email',
                  },
                })}
              />
            </label>
            {errors.email && (
              <span className={style.error}>{errors.email.message}</span>
            )}
          </div>
          <ButtonPrimary
            btnClass={cn(
              disableSubmit ? 'button-primary--disabled' : '',
              style.button
            )}
            isSubmit
          >
            Subscribe
          </ButtonPrimary>
        </form>
      </div>
    </section>
  )
}

export default Subscribe
