// extracted by mini-css-extract-plugin
export var animateCircle = "subscribe-module--animate-circle--7oZ8U";
export var bounce = "subscribe-module--bounce--nWFTI";
export var button = "subscribe-module--button--Nlf55";
export var close = "subscribe-module--close--NJSCa";
export var description = "subscribe-module--description--8TqOC";
export var error = "subscribe-module--error--tPl3v";
export var form = "subscribe-module--form--iFNwl";
export var input = "subscribe-module--input--VYU+H";
export var item = "subscribe-module--item--lfHyh";
export var label = "subscribe-module--label--YDv4x";
export var ldsRing = "subscribe-module--lds-ring--tm2yx";
export var popup = "subscribe-module--popup--HQm42";
export var popupContent = "subscribe-module--popupContent--Hohk2";
export var title = "subscribe-module--title--8VzXl";
export var wrap = "subscribe-module--wrap--xYQmW";