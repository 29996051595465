import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import scrollTo from 'gatsby-plugin-smoothscroll'
import * as style from './blog-filter.module.scss'
import HiddenScrollItem from '../../../common/HiddenScrollItem/HiddenScrollItem'
import useOutsideClickEvent from '../../../../hooks/useOutsideClickEvent'

const BlogFilters = ({ categories, setActive, activeCategory, scrollToId }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const dropdownRef = useRef(null)
  const click = useOutsideClickEvent(dropdownRef)

  const triggerActive = (category) => {
    setActive(category)
    setDropdownOpen(false)
    scrollTo(`#${scrollToId}`)
  }

  useEffect(() => {
    if (!click) setDropdownOpen(false)
  }, [click])

  return (
    <div className={style.wrap} ref={dropdownRef}>
      <HiddenScrollItem id={scrollToId} positionData={0} />
      <h5 className={style.title}>
        <span>Categories</span>
      </h5>
      <div
        role="button"
        tabIndex={0}
        onKeyUp={() => setDropdownOpen(!dropdownOpen)}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className={cn(
          style.dropdownWrap,
          dropdownOpen && style.dropdownWrapOpen
        )}
      >
        {activeCategory.name === 'All' ? (
          <span>All Categories</span>
        ) : (
          <span className={style.selectedCategory}>{activeCategory.name}</span>
        )}
      </div>
      <ul className={cn(style.dropdown, dropdownOpen && style.open)}>
        {categories.map((category) => (
          <li
            onClick={() => triggerActive(category)}
            key={category.name}
            className={cn(
              style.category,
              activeCategory.slug === category.slug && style.active
            )}
          >
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  )
}
export default BlogFilters
