// extracted by mini-css-extract-plugin
export var activeAvatar = "post-main-section-module--activeAvatar--cAmdM";
export var activeCite = "post-main-section-module--activeCite--HtMny";
export var animateCircle = "post-main-section-module--animate-circle--2FncY";
export var author = "post-main-section-module--author--eEcH5";
export var authorBlockquote = "post-main-section-module--authorBlockquote--qz6Su";
export var authorDesktop = "post-main-section-module--authorDesktop--R4Vrr";
export var authorMobile = "post-main-section-module--authorMobile--i+iX+";
export var authorName = "post-main-section-module--authorName--KJDG0";
export var authorWrap = "post-main-section-module--authorWrap--M1erj";
export var avatar = "post-main-section-module--avatar--5KTCa";
export var avatars = "post-main-section-module--avatars--+aND5";
export var bounce = "post-main-section-module--bounce--yp4KR";
export var container = "post-main-section-module--container--NVoO8";
export var description = "post-main-section-module--description--ybdBW";
export var info = "post-main-section-module--info--OFCtS";
export var ldsRing = "post-main-section-module--lds-ring--v1x84";
export var main = "post-main-section-module--main--h7Rv3";
export var mainBg = "post-main-section-module--mainBg--l1zam";
export var read = "post-main-section-module--read--wOapj";
export var singleAvatar = "post-main-section-module--singleAvatar--AIeuc";
export var title = "post-main-section-module--title--JDpf4";
export var titleHidden = "post-main-section-module--titleHidden--K3P4e";