import { useEffect, useState } from 'react'
import { POSTS_PER_PAGE } from '../types/types'
import useWindowSize from './useWindowSize'

const usePostPerPage = () => {
  const [postPerPage, setPostPerPage] = useState(POSTS_PER_PAGE.DESKTOP)
  const { width } = useWindowSize()

  useEffect(() => {
    let newPageWidth = POSTS_PER_PAGE.DESKTOP
    if (width >= 1024) {
      newPageWidth = POSTS_PER_PAGE.DESKTOP
    } else if (width < 768) {
      newPageWidth = POSTS_PER_PAGE.MOBILE
    } else if (width < 1024) {
      newPageWidth = POSTS_PER_PAGE.TABLET
    }

    setPostPerPage(newPageWidth)
  }, [width])

  return {
    itemsPerPage: postPerPage,
  }
}

export default usePostPerPage
