// extracted by mini-css-extract-plugin
export var animateCircle = "authors-slider-module--animate-circle--DJkBl";
export var blockButton = "authors-slider-module--blockButton--MmReK";
export var blockButtonNext = "authors-slider-module--blockButtonNext--afYZw";
export var blockButtonPrev = "authors-slider-module--blockButtonPrev--8DHk9";
export var blockCountry = "authors-slider-module--blockCountry--hueyP";
export var blockImages = "authors-slider-module--blockImages--fnsDQ";
export var blockImagesActive = "authors-slider-module--blockImagesActive--ta+wJ";
export var blockInfo = "authors-slider-module--blockInfo--9qftr";
export var blockInfoLight = "authors-slider-module--blockInfoLight--XRLWF";
export var blockItem = "authors-slider-module--blockItem--FYHlY";
export var blockItemActive = "authors-slider-module--blockItemActive--5YGPo";
export var blockName = "authors-slider-module--blockName--ilDVw";
export var blockNav = "authors-slider-module--blockNav--uVuob";
export var blockPortrait = "authors-slider-module--blockPortrait--UblFY";
export var blockText = "authors-slider-module--blockText--XVif8";
export var blockTitle = "authors-slider-module--blockTitle--peCig";
export var bounce = "authors-slider-module--bounce--doBtN";
export var ldsRing = "authors-slider-module--lds-ring--sq10J";
export var mainClients__blockName = "authors-slider-module--main-clients__block-name--7QDTp";