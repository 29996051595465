import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import * as style from './blog-plate.module.scss'
import keyGenerator from '../../../utils/keyGenerator'
import ButtonPrimary from '../../common/buttons/ButtonPrimary'

const BlogPlate = ({ data: { blogAuthors, blogPost, title, uri }, tags }) => {
  const plateTags = tags || blogPost.tags
  const imageData =
    blogPost.image && getImage(blogPost.image.localFile.childImageSharp)

  const autor = blogAuthors.nodes[0]
  const avatar = autor?.blogAuthor.photo

  return (
    <Link to={uri}>
      <div className={style.wrap}>
        <div className={style.info}>
          <div className={style.tags}>
            {plateTags.map((e, i) => (
              <div key={keyGenerator(i, e.name)} className={style.tag}>
                <span>{e.name}</span>
              </div>
            ))}
          </div>
          <div className={style.titleWrap}>
            <p className={style.title}>{title}</p>
            {blogPost.blogPrewiev && (
              <p className={style.hiddenText}>{blogPost.blogPrewiev}</p>
            )}
          </div>

          <div className={style.user}>
            <img
              className={style.avatar}
              src={avatar?.localFile.url}
              alt={avatar?.altText}
              width={56}
              height={56}
            />

            <div>
              <p className={style.authorName}>{autor?.name}</p>
              <p className={style.timeToRead}>
                {blogPost.minutesToRead} min read
              </p>
            </div>
          </div>
          <div className={style.hiddenButton}>
            <ButtonPrimary>More</ButtonPrimary>
          </div>
        </div>

        <GatsbyImage
          className={style.image}
          alt={blogPost.image.altText}
          image={imageData}
        />
      </div>
    </Link>
  )
}

export default BlogPlate
