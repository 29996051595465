import React from 'react'
import * as style from './blog-tags.module.scss'
import keyGenerator from '../../../utils/keyGenerator'

const BlogTags = ({ tags }) => (
  <div className={style.tags}>
    {tags.map((e, i) => (
      <div key={keyGenerator(i, e.name)} className={style.tag}>
        <span>{e.name}</span>
      </div>
    ))}
  </div>
)

export default BlogTags
