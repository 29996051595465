// extracted by mini-css-extract-plugin
export var animateCircle = "page-main-section-module--animate-circle--o-7cc";
export var bounce = "page-main-section-module--bounce--uxqFI";
export var button = "page-main-section-module--button--X1kvB";
export var container = "page-main-section-module--container--lO-aF";
export var description = "page-main-section-module--description--n9noB";
export var headings = "page-main-section-module--headings--SaWP7";
export var image = "page-main-section-module--image--4IPOz";
export var imageMobile = "page-main-section-module--imageMobile--1CFvi";
export var ldsRing = "page-main-section-module--lds-ring--939qD";
export var main = "page-main-section-module--main--KRo2E";
export var mainBg = "page-main-section-module--mainBg--F73uV";
export var title = "page-main-section-module--title--MHBtS";
export var titleHidden = "page-main-section-module--titleHidden--UrwzJ";