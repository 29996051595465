import React from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import * as style from './page-main-section.module.scss'

const PageMainSection = ({
  title,
  hiddenTitle,
  description,
  children,
  background,
  image,
  imageMobile,
  content,
  newClass,
}) => {
  const img = () =>
    background ? { backgroundImage: `url('${background}')` } : {}
  return (
    <section
      style={img()}
      className={className(style.main, background && style.mainBg, newClass)}
    >
      <div className={className(style.container, 'container')}>
        <div className={background && style.headings}>
          <Fade left>
            {hiddenTitle ? (
              <>
                <h1 className={style.titleHidden}>{hiddenTitle}</h1>
                <p className={className(style.title, 'text-color--primary')}>
                  {title}
                </p>
              </>
            ) : (
              <h1 className={className(style.title, 'text-color--primary')}>
                {title}
              </h1>
            )}
          </Fade>
          <Fade bottom>
            <p className={className('text-color--primary', style.description)}>
              {description}
            </p>
          </Fade>
        </div>

        {children &&
          (content ? (
            <>{children}</>
          ) : (
            <div className={style.button}>{children}</div>
          ))}
      </div>
      {image && (
        <div className={style.image}>
          <img
            width={500}
            height={500}
            src={`/images/${image}`}
            alt="background"
          />
        </div>
      )}
      {imageMobile && (
        <div className={style.imageMobile}>
          <img
            width={500}
            height={500}
            src={`/images/${imageMobile}`}
            alt="background"
          />
        </div>
      )}
    </section>
  )
}

PageMainSection.defaultProps = {
  title: '',
  hiddenTitle: '',
  background: '',
  description: '',
  image: '',
  children: null,
  content: false,
  newClass: '',
}

PageMainSection.propTypes = {
  title: PropTypes.string,
  hiddenTitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.bool,
  newClass: PropTypes.string,
}
export default PageMainSection
