const ALL_TAGS_NAME = 'All'

function setName(category) {
  const str = category.charAt(0).toUpperCase() + category.slice(1)
  return str.replace(/\s+/g, '-').replace(/&/g, 'and').toLowerCase()
}

function initNames(posts) {
  return posts.map((item) => ({
    name: item,
    slug: setName(item),
  }))
}

const initTagsAndCategories = (posts) => {
  const initTags = [ALL_TAGS_NAME]
  const initCategories = [ALL_TAGS_NAME]

  posts.forEach((node) => {
    node.categories.nodes.forEach((category) => {
      if (!initCategories.includes(category.name)) {
        initCategories.push(category.name)
      }
    })
    node.blogPost.tags?.forEach((tag) => {
      if (!initTags.includes(tag.name)) {
        initTags.push(tag.name)
      }
    })
  })
  return {
    initTags: initNames(initTags),
    initCategories: initNames(initCategories),
  }
}

const generateTagsBySelectedCategory = (category, posts) => {
  const arr = [ALL_TAGS_NAME]
  posts.forEach((post) =>
    post.categories.nodes.forEach((cat) => {
      if (cat.name === category.name) {
        post.blogPost.tags.forEach((tag) => {
          if (!arr.includes(tag.name)) arr.push(tag.name)
        })
      }
    })
  )
  return initNames(arr)
}

const sortActiveTag = (unsortedTags, activeTagName) =>
  unsortedTags
    .sort((a, b) => {
      if (a.name === activeTagName) return -1
      if (b.name === activeTagName) return 1
      return 0
    })
    .sort((a, b) => {
      if (a.name === ALL_TAGS_NAME) return -1
      if (b.name === ALL_TAGS_NAME) return 1
      return 0
    })

const filterPosts = (posts, activeCategoryName, activeTagName) => {
  const arr = []
  posts.forEach((post) => {
    if (
      (activeCategoryName === ALL_TAGS_NAME ||
        post.categories.nodes.some((c) => c.name === activeCategoryName)) &&
      (activeTagName === ALL_TAGS_NAME ||
        post.blogPost.tags.some((tg) => tg.name === activeTagName))
    ) {
      arr.push(post)
    }
  })
  return arr
}

const setPaginationChunk = (posts, itemsPerPage) =>
  posts.reduce((resultArray, item, index) => {
    const newArray = [...resultArray]
    const chunkIndex = Math.floor(index / itemsPerPage)

    if (!newArray[chunkIndex]) {
      newArray[chunkIndex] = []
    }

    newArray[chunkIndex].push(item)

    return newArray
  }, [])

export {
  ALL_TAGS_NAME,
  setPaginationChunk,
  filterPosts,
  sortActiveTag,
  generateTagsBySelectedCategory,
  initTagsAndCategories,
  setName,
  initNames,
}
