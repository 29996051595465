import * as React from 'react'
import cn from 'classnames'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { useEffect, useState } from 'react'
import * as style from './blog-pagination.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'

const BlogPagination = ({ pages, setCurrentPage, currentPage, scrollToId }) => {
  const [paginationChunk, setPaginationChunk] = useState([])

  useEffect(() => {
    const arr = [...Array(pages).keys()]
    setPaginationChunk(arr)
  }, [pages])

  const triggerPage = (page) => {
    setCurrentPage(page)
    scrollTo(`#${scrollToId}`)
  }

  const unactiveNextButton = () => currentPage === pages - 1
  const unactivePrevButton = () => currentPage === 0
  const showPaginationItem = (index) => {
    const firstPage = index < 3 && currentPage < 2
    const paginationRow = !(index > currentPage + 1 || index < currentPage - 1)
    return paginationRow || firstPage
  }

  return (
    <div className={style.pagination}>
      <button
        style={{ marginRight: '10px' }}
        className={cn(
          style.button,
          unactivePrevButton() && style.unactiveButton
        )}
        type="button"
        onClick={() => triggerPage(currentPage - 1)}
      >
        <span>Back</span>
      </button>
      <ul className={style.list}>
        {paginationChunk.map((_, index) => (
          <li key={keyGenerator(index)}>
            <div
              className={cn(
                showPaginationItem(index) && style.show,
                style.page,
                index === currentPage && style.active
              )}
              role="button"
              tabIndex={0}
              onClick={() => triggerPage(index)}
              onKeyDown={() => triggerPage(index)}
            >
              {index + 1}
            </div>
          </li>
        ))}
      </ul>
      <button
        style={{ marginLeft: '10px' }}
        className={cn(
          style.button,
          unactiveNextButton() && style.unactiveButton
        )}
        type="button"
        onClick={() => triggerPage(currentPage + 1)}
      >
        <span>Next</span>
      </button>
    </div>
  )
}

export default BlogPagination
