// extracted by mini-css-extract-plugin
export var animateCircle = "blog-plate-module--animate-circle--2yzl+";
export var authorName = "blog-plate-module--authorName--ccBik";
export var avatar = "blog-plate-module--avatar---6ABp";
export var bounce = "blog-plate-module--bounce--E25NP";
export var hiddenButton = "blog-plate-module--hiddenButton--GDdCJ";
export var hiddenText = "blog-plate-module--hiddenText--iUr13";
export var image = "blog-plate-module--image--8715G";
export var info = "blog-plate-module--info--LsHOK";
export var ldsRing = "blog-plate-module--lds-ring--RylH7";
export var tag = "blog-plate-module--tag--8KmCy";
export var tags = "blog-plate-module--tags--Sdv78";
export var timeToRead = "blog-plate-module--timeToRead--C+cbX";
export var title = "blog-plate-module--title--kX7+h";
export var titleWrap = "blog-plate-module--titleWrap--dD+60";
export var user = "blog-plate-module--user--sIrg9";
export var wrap = "blog-plate-module--wrap--Bh7Mp";