import React, { createRef, useEffect, useState } from 'react'
import cn from 'classnames'
import scrollTo from 'gatsby-plugin-smoothscroll'
import * as style from './blog-tags-filter.module.scss'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'
import HiddenScrollItem from '../../../common/HiddenScrollItem/HiddenScrollItem'

const BlogTagsFilter = ({ tags, activeTag, setActiveTag, scrollToId }) => {
  const [maxHeight, setMaxHeight] = useState(0)
  const [wrapHeight, setWrapHeight] = useState(0)
  const [maxTags, setMaxTags] = useState(9)
  const [overflow, setOverflow] = useState(false)
  const tagsId = 'blog-tags-filter'
  const tagRef = createRef()
  const tagWrapRef = createRef()

  useEffect(() => {
    setMaxHeight(tagRef.current.offsetHeight * maxTags + 20)
    setWrapHeight(tagWrapRef.current.offsetHeight)
  }, [tags])

  useEffect(() => {
    setOverflow(wrapHeight > maxHeight)
  }, [wrapHeight, maxHeight])

  const toggleActiveTag = (tag) => {
    setActiveTag(tag)
    scrollTo(`#${scrollToId}`)
  }

  const toggleShowMore = () => {
    if (!overflow) {
      scrollTo(`#${tagsId}`)
    }
    setOverflow(!overflow)
  }

  return (
    <div>
      <h5 className={style.heading}>
        <HiddenScrollItem id={tagsId} positionData={0} />
        Tags
      </h5>
      <div className={style.tags}>
        <div
          className={cn(style.tagsOverflow)}
          style={{ maxHeight: overflow ? maxHeight : 'unset' }}
        >
          <div className={style.tagsWrap} ref={tagWrapRef}>
            {tags.map((tag, i) => (
              <div
                ref={tagRef}
                role="button"
                tabIndex={0}
                onClick={() => toggleActiveTag(tag)}
                onKeyDown={() => toggleActiveTag(tag)}
                key={tag.slug}
                className={cn(
                  !i && style.all,
                  style.tag,
                  activeTag.slug === tag.slug && style.active
                )}
              >
                {tag.name}
              </div>
            ))}
          </div>
        </div>

        {wrapHeight > maxHeight && (
          <ButtonPrimary
            onClick={toggleShowMore}
            isLight
            arrowDirection={overflow ? 'down' : 'up'}
          >
            {overflow ? 'Show more' : 'Show less'}
          </ButtonPrimary>
        )}
      </div>
    </div>
  )
}

export default BlogTagsFilter
