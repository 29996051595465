// extracted by mini-css-extract-plugin
export var active = "blog-pagination-module--active--jis-v";
export var animateCircle = "blog-pagination-module--animate-circle--kt5WQ";
export var bounce = "blog-pagination-module--bounce--WLBch";
export var button = "blog-pagination-module--button---B0Gi";
export var ldsRing = "blog-pagination-module--lds-ring--0BgHP";
export var list = "blog-pagination-module--list--rKXe7";
export var page = "blog-pagination-module--page--8qkAP";
export var pagination = "blog-pagination-module--pagination--dNt5b";
export var show = "blog-pagination-module--show--SBujk";
export var unactiveButton = "blog-pagination-module--unactiveButton--0fnbl";