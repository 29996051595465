import * as React from 'react'
import { graphql } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import MainLayout from '../layouts/MainLayout'
import PageMainSection from '../components/reusable/PageMainSection/PageMainSection'
import Section from '../components/common/SectionComponent/Section'

import LastPostComponent from '../components/Pages/Blog/LastPostComponent/LastPostComponent'
import BlogPlates from '../components/Pages/Blog/BlogPlates/BlogPlates'
import Subscribe from '../components/Pages/OnePost/Subscribe/Subscribe'
import ButtonPrimary from '../components/common/buttons/ButtonPrimary'

const Blog = ({
  data: {
    wpPage: {
      metaTags,
      page_blog: {
        header: { description, title },
      },
    },
    allWpPost: { nodes },
  },
  location,
}) => {
  const sortedPosts = nodes.sort((a, b) => new Date(b.date) - new Date(a.date))
  const lastPost = sortedPosts[0]

  const buttonScroll = () => {
    scrollTo('#subscribe-section')
  }
  return (
    <MainLayout seoData={metaTags} location={location}>
      <PageMainSection
        title={title}
        description={description}
        image="blog-dt.png"
        imageMobile="blog-dt_mobile.png"
      >
        <div style={{ marginBottom: 10 }}>
          <ButtonPrimary onClick={buttonScroll}>Subscribe</ButtonPrimary>
        </div>
      </PageMainSection>

      <Section isLight hideText>
        <LastPostComponent post={lastPost} />
        <BlogPlates posts={sortedPosts} />
      </Section>
      <Subscribe />
    </MainLayout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 46 }) {
      page_blog {
        header {
          description
          title
        }
      }
      metaTags {
        seoTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
    }
    allWpPost {
      nodes {
        date
        title
        uri
        categories {
          nodes {
            name
          }
        }
        blogAuthors {
          nodes {
            name
            blogAuthor {
              photo {
                altText
                localFile {
                  url
                }
              }
            }
          }
        }
        blogPost {
          blogPrewiev
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          title
          minutesToRead
          tags {
            name
          }
          author {
            name
            blogAuthor {
              photo {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  url
                }
                altText
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
